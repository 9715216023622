import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { RootState } from '../../../setup';
import { FORM_STATUSES, compareURLs, useQuery } from '../../modules/utils';
import { runTest } from '../../modules/auth/redux/TestRunCRUD';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { WebsiteModel } from '../../modules/auth/models/OrganizationModel';
import { viewWebsite } from '../../modules/auth/redux/OrganizationCRUD';
import { ErrorMsg } from '../layout/MasterLayout';

const schema = Yup.object().shape({
    url: Yup.lazy(value => {
      if (/((http([s]){0,1}:\/\/){0,1}(localhost|127.0.0.1){1}(([:]){0,1}[\0-9]{4}){0,1}\/{0,1}){1}/.test(value)) {
        return Yup.string().required('URL is required');
      }
      return Yup.string().required().url('Invalid URL format');
    }),
    name: Yup.string().required('Name is required'),
    testMode: Yup.string()
    .required('Test Mode is required'),
    wcagVersion: Yup.string()
    .required('WCAG Version is required'),
    wcagLevel: Yup.string()
    .required('WCAG Level is required'),
    disableExperimental: Yup.bool().required('Invalid disable experimental flag'),
  });

const RunTest: React.FC = () => {
    const location = useLocation();
    const { pathname } = location;
    const queryParams: URLSearchParams = useQuery();
    const url = decodeURIComponent(queryParams.get('url') || '');
    const testMode = decodeURIComponent(queryParams.get('testMode') || 'single_page');
    const wcagVersion = decodeURIComponent(queryParams.get('wcagVersion') || '');
    const wcagLevel = decodeURIComponent(queryParams.get('wcagLevel') || '');
    const [loading, setLoading] = useState(false)
    const [websiteData, setWebsiteData] = useState<Record<string, WebsiteModel>>({});
    const [dataError, setDataError] = useState<DataError>();
    const initialValues = { url: undefined, testMode, name: '', wcagVersion, wcagLevel, disableExperimental: true };

    const formik = useFormik<any>({
        initialValues,
        validationSchema: schema,
        onSubmit: (values, { setStatus }) => {
            setLoading(true);
            const testSettings = { testMode: values.testMode, wcagVersion: values.wcagVersion, wcagLevel: values.wcagLevel, disableExperimental: JSON.parse(values.disableExperimental) };
            runTest(values.name, values.url, testSettings, websiteData[values.url].id).then(({ data: { id } }) => {
                setLoading(false);
                setStatus({ state: FORM_STATUSES.SUCCESS, response: { id } });
            }).catch((e) => {
                setLoading(false);
                const errorData = e?.response?.data;
                const lbErrorCode = errorData?.error?.code;
                let errorMsg;
                if (lbErrorCode == "ER_EXISTING_RUN") {
                    errorMsg = 'URL has an existing running test. Wait for it to complete or cancel it to trigger a new test';
                } else if (lbErrorCode == "ER_UNREACHABLE_URL") {
                    errorMsg = errorData?.error?.message;
                }  else if (lbErrorCode == "ER_USER_DEPT_MISSING") {
                    errorMsg = errorData?.error?.message;
                } else {
                    errorMsg = 'Server Error! Cannot run the test';
                }
                setStatus({state: FORM_STATUSES.FAILURE, response: {error: errorMsg}});
            });
        },
    });

    useEffect(() => {
        viewWebsite().then((result) => {
            const websiteByUrl: Record<string, WebsiteModel> = {};
            if (result.data) {
                for (const w of result.data) {
                    websiteByUrl[w.url] = w;
                }
                const selectedWebsite = result.data.find(website => compareURLs(website.url, url));
                if (selectedWebsite) {
                    formik.setFieldValue('url', selectedWebsite.url);
                }
            }
            setWebsiteData(websiteByUrl);
        }).catch(e => setDataError({ error: "Server Error! Cannot fetch website data!" }));
    }, []);

    if (dataError) {
        return (
            <div className='card mb-5 mb-xl-10'>
                <div
                    className='card-header border-0'
                >
                    <div>
                        <br />
                        <div className='mb-lg-15 alert alert-danger d-flex flex-column-fluid flex-center'>
                            <div className='alert-text font-weight-bold'>{dataError.error}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    if (formik.status && formik.status.state == FORM_STATUSES.SUCCESS) {
        return <Redirect to={{...location, pathname: `/v2/core/test/${formik.status.response?.id}/overview`}}  />;
    }

    return (
        <>
            <div className="bredcrumb-cnntr">
                <ul>
                    <li><Link to={`/v2/dashboard`}>Home</Link></li>
                    <li>Run Test</li>
                </ul>
            </div>
            <div className="site-head">
                <h1 className="main-title">Run Test</h1>
            </div>
            <div className="row">
                {formik.status && formik.status.state == FORM_STATUSES.FAILURE ? (
                    <div className='alert-danger' style={{ padding: 5 }}>
                        <div className='alert-text font-weight-bold'>{formik.status.response?.error}</div>
                    </div>
                ) : null}
                <div className="col-md-12">
                    <form onSubmit={formik.handleSubmit} noValidate className='test-form'>
                        <div className='row'>
                            <div className='col-md-6'>
                                <label htmlFor='s_website'>Enter URL</label>
                                <select
                                    id='s_website'
                                    className='form-select form-select-solid form-select-lg'
                                    {...formik.getFieldProps('url')}
                                >
                                    <option value="">Select Url</option>
                                    {Object.values(websiteData)?.map(website => (
                                        <option value={website.url} selected={compareURLs(website.url, url)}>{website.url}</option>
                                    ))}
                                </select>
                                {formik.touched.url && formik.errors.url && (
                                    <ErrorMsg>
                                        <div className='fv-help-block'>{formik.errors.url}</div>
                                    </ErrorMsg>
                                )}
                            </div>

                            <div className='col-md-6'>
                                <label htmlFor='test-name'>Test Name for reference</label>
                                <input
                                    id='test-name'
                                    type='text'
                                    placeholder='Enter name'
                                    {...formik.getFieldProps('name')}
                                />
                                {formik.touched.name && formik.errors.name && (
                                    <ErrorMsg>
                                        <div className='fv-help-block'>{formik.errors.name}</div>
                                    </ErrorMsg>
                                )}
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div role="radiogroup" aria-labelledby="testingmodel" className="testing_mode">
                                    <span className="testingmode1" id="testingmodel">Testing Mode</span>

                                    <input type="radio" id="singlewebpage" defaultChecked={true} {...formik.getFieldProps('testMode')}
                                        value='single_page'
                                        style={{ marginTop: -1, verticalAlign: 'middle' }} />
                                    <label htmlFor="singlewebpage" style={{ marginLeft: 5 }}>Single Webpage</label>

                                    <input type="radio" id="entirewebpage" {...formik.getFieldProps('testMode')}
                                        value='full_site'
                                        style={{ marginTop: -1, verticalAlign: 'middle' }} />
                                    <label htmlFor="entirewebpage" style={{ marginLeft: 5, marginRight: 10 }}>Entire Webpage</label>
                                    <span className="dropdown help-link">
                                        <a href="#" className="dropdown-toggle"
                                            data-toggle="dropdown" role="button"
                                            aria-haspopup="true" aria-expanded="false"
                                            title="help"><i className="fa fa-question-circle-o"
                                                aria-hidden="true"></i></a>
                                        <div className="dropdown-menu">
                                            <small>All pages under this top level URL</small>
                                        </div>
                                    </span>
                                    {formik.touched.testMode && formik.errors.testMode && (
                                        <ErrorMsg>
                                            <div className='fv-help-block'>{formik.errors.testMode}</div>
                                        </ErrorMsg>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <label htmlFor="select_compliance">Compliance</label>
                                <select
                                    id='select_compliance'
                                    className='form-select form-select-solid form-select-lg fs-7'
                                    {...formik.getFieldProps('wcagVersion')}
                                >
                                    <option value="">Select WCAG Version</option>
                                    <option value='WCAG 2.0 without ARIA'>WCAG 2.0 without ARIA</option>
                                    <option value='WCAG 2.1 without ARIA'>WCAG 2.1 without ARIA</option>
                                    <option value='WCAG 2.0'>WCAG 2.0</option>
                                    <option value='WCAG 2.1'>WCAG 2.1</option>
                                </select>
                                {formik.touched.wcagVersion && formik.errors.wcagVersion && (
                                    <ErrorMsg>
                                        <div className='fv-help-block'>{formik.errors.wcagVersion}</div>
                                    </ErrorMsg>
                                )}
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="select_level">WCAG Level</label>
                                <select
                                    id='select_level'
                                    className='form-select form-select-solid form-select-lg fs-7'
                                    {...formik.getFieldProps('wcagLevel')}
                                >
                                    <option value="">Select WCAG Level</option>
                                    <option value='Level A'>Level A</option>
                                    <option value='Level AA'>Level AA</option>
                                    <option value='Level AAA'>Level AAA</option>
                                </select>
                                {formik.touched.wcagLevel && formik.errors.wcagLevel && (
                                    <ErrorMsg>
                                        <div className='fv-help-block'>{formik.errors.wcagLevel}</div>
                                    </ErrorMsg>
                                )}
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-md-12 testingmode1'>
                                <button type='submit' className='btn btn-red' disabled={loading}>
                                    {!loading && 'Run Test'}
                                    {loading && (
                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                            Please wait...{' '}
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    )}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export { RunTest }
